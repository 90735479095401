import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Input, notification } from "antd";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { incNumber } from "../../Redux/actions";
import swal from "sweetalert";
import Breadcrumb from "../common/breadcrumb";

const Editpancard = () => {
    const { lid } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showLoading, setShowLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem("userResponse"));
    const [balance, setBalance] = useState(0);
    const [userFormDetails, setUserFormDetails] = useState([]);
    const [form] = Form.useForm();

    // Initializing state with fields from the mongoose schema
    const [Data, SetData] = useState({
        createdBy: userData?.user._id,
        createdByName: userData?.user.name,
        application_type: "pancard",
        formPrice: "",
        status: "IN-PROGRESS",
        firstName: "",
        middleName: "",
        lastName: "",
        fathersFullName: "",
        Dob: "",
        email: "", // Added email field here
        panNumber: "",
        Address: "",
        mobileNO: "",
        note: "",
        adharCard: "",
        signature: "",
        photo: "", // Added photo field
        pincode: "",
        gender: "",
    });

    const [docs, setDocs] = useState({
        adharCard: null,
        signature: null,
        photo: null, // Added photo upload field
    });

    useEffect(() => {
        const fetchFormPrice = async () => {
            try {
                const res = await axios.get(`https://api.maharashtraseva.com/api/formPrice`);
                const response = res.data;
                const filtered = response.filter((item) => item.userID === userData.user._id);
                const userFormDetails = filtered.length === 0 ? response.filter((item) => item.userID === "ALL") : filtered;
                setUserFormDetails(userFormDetails);
                SetData((prevData) => ({ ...prevData, formPrice: userFormDetails[0]?.price || 0 }));
            } catch (err) {
                console.error("Error fetching form price:", err);
            }
        };
        fetchFormPrice();
    }, [userData.user._id]);

    const reducer = useSelector((state) => state.changeNumber);
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                if (userData) {
                    const res = await axios.get(`https://api.maharashtraseva.com/api/recharge`);
                    const allData = res.data.filter((ele) => ele.user === userData.user._id);
                    let amount = 0;
                    let expense = 0;
                    if (allData.length > 0) {
                        allData.forEach((item) => {
                            if (item.isExpence === "true") {
                                expense += item.amount;
                            } else {
                                amount += item.amount;
                            }
                        });
                    }
                    setBalance(amount - expense);
                }
            } catch (err) {
                console.error("Error fetching balance:", err);
            }
        };
        fetchBalance();
    }, [reducer, userData]);

    const handleFormSubmit = async (values) => {
        const fileErrors = fileValidate(docs);

        if (Object.keys(fileErrors).length === 0) {
            setShowLoading(true);
            if (balance > Data.formPrice) {
                try {
                    const res = await axios.post(`https://api.maharashtraseva.com/api/editPanCard/create`, Data);
                    await uploadDocuments(res.data.data._id);
                    resetForm();
                    dispatch(incNumber());
                    navigate("/RetailerDashboard");
                } catch (err) {
                    console.error("Error submitting form:", err);
                    setShowLoading(false);
                }
            } else {
                notification.error({ message: "Insufficient balance. Please recharge." });
                setShowLoading(false);
            }
        } else {
            setShowLoading(false);
            swal("Please fill in the required fields!");
        }
    };

    const uploadDocuments = async (id) => {
        const formData = new FormData();
        formData.append("adharCard", docs.adharCard);
        formData.append("signature", docs.signature);
        formData.append("photo", docs.photo); // Added photo upload

        try {
            await axios.put(`https://api.maharashtraseva.com/api/editPanCard/${id}/upload`, formData);
            console.log("Documents Uploaded successfully.");
        } catch (err) {
            console.error("Error uploading documents:", err);
        }
    };

    const fileValidate = (files) => {
        const fileErrors = {};
        if (!files.adharCard) {
            fileErrors.adharCard = "Aadhar Card is required";
        } else if (files.adharCard.size > 1024 * 1024) {
            fileErrors.adharCard = "File size exceeds the limit of 1MB";
        }
        if (!files.signature) {
            fileErrors.signature = "Signature is required";
        } else if (files.signature.size > 500 * 1024) {
            fileErrors.signature = "File size exceeds the limit of 500KB";
        }
        if (!files.photo) {
            fileErrors.photo = "Photo is required";
        } else if (files.photo.size > 1024 * 1024) {
            fileErrors.photo = "File size exceeds the limit of 1MB";
        }
        return fileErrors;
    };

    const resetForm = () => {
        SetData({
            createdBy: userData?.user._id,
            createdByName: userData?.user.name,
            application_type: "pancard",
            formPrice: "",
            status: "IN-PROGRESS",
            firstName: "",
            middleName: "",
            lastName: "",
            fathersFullName: "",
            Dob: "",
            email: "", // Resetting email field
            panNumber: "",
            Address: "",
            mobileNO: "",
            note: "",
            adharCard: "",
            signature: "",
            photo: "", // Resetting photo field
            pincode: "",
            gender: "",
        });
        form.resetFields();
    };
    return (
        <Fragment>
            <Breadcrumb title={"Correction Pan Card  "} parent={"license"} />

            <Container fluid={true}>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Form
                                    form={form}
                                    name="basic"
                                    autoComplete="off"
                                    layout={"vertical"}
                                    onFinish={handleFormSubmit}
                                >
                                    <div className="row">
                                        {/* First Name */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> First Name</>}
                                                name="firstName"
                                                rules={[{ required: true, message: "First Name is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter First Name"
                                                    value={Data.firstName}
                                                    onChange={(e) => SetData({ ...Data, firstName: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Email */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> Email</>}
                                                name="email"
                                                rules={[{ required: true, message: "Email is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Email"
                                                    value={Data.email}
                                                    onChange={(e) => SetData({ ...Data, email: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Date of Birth */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> Date of Birth</>}
                                                name="Dob"
                                                rules={[{ required: true, message: "Date of Birth is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    type="date" // Changed to date input type
                                                    value={Data.Dob}
                                                    onChange={(e) => SetData({ ...Data, Dob: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Other fields go here */}
                                        {/* Add any other necessary fields from the model */}
                                        {/* Father's Full Name */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> Father's Full Name</>}
                                                name="fathersFullName"
                                                rules={[{ required: true, message: "Father's Full Name is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Father's Full Name"
                                                    value={Data.fathersFullName}
                                                    onChange={(e) => SetData({ ...Data, fathersFullName: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* PAN Number */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> PAN Number</>}
                                                name="panNumber"
                                                rules={[{ required: true, message: "PAN Number is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter PAN Number"
                                                    value={Data.panNumber}
                                                    onChange={(e) => SetData({ ...Data, panNumber: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Address */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> Address</>}
                                                name="Address"
                                                rules={[{ required: true, message: "Address is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Address"
                                                    value={Data.Address}
                                                    onChange={(e) => SetData({ ...Data, Address: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Mobile Number */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> Mobile Number</>}
                                                name="mobileNO"
                                                rules={[{ required: true, message: "Mobile Number is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Mobile Number"
                                                    value={Data.mobileNO}
                                                    onChange={(e) => SetData({ ...Data, mobileNO: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Pincode */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> Pincode</>}
                                                name="pincode"
                                                rules={[{ required: true, message: "Pincode is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Pincode"
                                                    value={Data.pincode}
                                                    onChange={(e) => SetData({ ...Data, pincode: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Gender */}
                                        <div className="col-md-4">
                                            <Form.Item
                                                label={<><span className="red">*</span> Gender</>}
                                                name="gender"
                                                rules={[{ required: true, message: "Gender is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Gender"
                                                    value={Data.gender}
                                                    onChange={(e) => SetData({ ...Data, gender: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>
                                        {/* Upload Aadhar Card */}
                                        <div className="col-md-6">
                                            <Form.Item
                                                label={<><span className="red">*</span> Upload Aadhar Card</>}
                                                name="adharCard"
                                                valuePropName="file"
                                                getValueFromEvent={(e) => e.file}
                                                rules={[{ required: true, message: "Aadhar Card is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    type="file"
                                                    onChange={(e) => setDocs({ ...docs, adharCard: e.target.files[0] })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Upload Signature */}
                                        <div className="col-md-6">
                                            <Form.Item
                                                label={<><span className="red">*</span> Upload Signature</>}
                                                name="signature"
                                                valuePropName="file"
                                                getValueFromEvent={(e) => e.file}
                                                rules={[{ required: true, message: "Signature is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    type="file"
                                                    onChange={(e) => setDocs({ ...docs, signature: e.target.files[0] })}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Upload Photo */}
                                        <div className="col-md-6">
                                            <Form.Item
                                                label={<><span className="red">*</span> Upload Photo</>}
                                                name="photo"
                                                valuePropName="file"
                                                getValueFromEvent={(e) => e.file}
                                                rules={[{ required: true, message: "Photo is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    type="file"
                                                    onChange={(e) => setDocs({ ...docs, photo: e.target.files[0] })}
                                                />
                                            </Form.Item>
                                        </div>


                                    </div>

                                    {/* Submit Button */}
                                    <div className="text-end">
                                        <Button
                                            type="primary"
                                            className="btn btn-primary btn-elevate"
                                            htmlType="submit"
                                            loading={showLoading}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Editpancard