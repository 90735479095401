
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate } from "react-router-dom";
// import Table_admin from "./Table_admin";
// import MainTable_admin from "./mainTable_admin";
import { MdNotificationsActive } from "react-icons/md";
import Table_form11 from "./Table_form11";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import DataTable from "react-data-table-component";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Form, Radio, Select, DatePicker } from "antd";

const Retailer_console = () => {
  const navigate = useNavigate();
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  const [application_type, setapplication_type] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchdata, setsearch] = useState("");
  const [TCform_alert, setTCform_alert] = useState([]);
  const form4SingleUser = useSelector(
    (state) => state.singleUserTehsilForms.Tehsilforms
  );
  const formEle = useSelector((state) => state.singleUserForms.forms);
  const reducer = useSelector((state) => state.changeNumber);

  const [allData, SetAllData] = useState(formEle);
  const [Form11Data, setForm11Data] = useState(formEle);
  const [FilteredData, setfilteredData] = useState(formEle);
  useEffect(() => {
    axios
      .get("https://api.maharashtraseva.com/api/getAll/Form11")
      .then(async (res) => {
        const data = await res.data;
        console.log(43, data);
        if (formEle?.length <= 0) {
          const filter = data.filter(
            (item) => item.createdByName == userRespons.user.name
          );
          setfilteredData(filter);
          SetAllData(filter);
          setForm11Data(filter);
          // setfilteredData(data);
        } else {
        
          setfilteredData(formEle);
          SetAllData(formEle);
          setForm11Data(formEle);
        }
      })
      .catch((err) => console.log(err));
  }, [reducer]);
  const handler = () => {
    if (application_type != null && status != null) {
      const filtered = allData.filter((item) => {
        if (
          item.application_type === application_type &&
          item.status === status
        ) {
          return item;
        }
      });
      setForm11Data(filtered);
      setfilteredData(filtered);
    } else {
      setfilteredData(allData);
      setForm11Data(allData);
    }
  };

  useEffect(() => {
    handler();
  }, [application_type, status]);

  const searchByName = (e) => {
    setsearch(e.target.value);
    const value = e.target.value;

    if (value.length > 0) {
      const searchdata = Form11Data.filter((item, index) => {
        if (item.createdByName != undefined && item.createdByName.length > 0) {
          const name = item.createdByName;
          if (name.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        }
      });
      setfilteredData(searchdata);
    } else {
      setfilteredData(Form11Data);
    }
  };
  return (
    <>
      <Breadcrumb title={" Application Console :"} parent={"Certificate"} />
      <div
        className="border p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div
          className="col-lg-12 text-center border rounded"
          style={{ backgroundColor: "#e0e0e0" }}
        >
          <h3 class="box-title">
            <span id="CPH_lbl_AppDtl">Application Type</span>
          </h3>
        </div>
        <Form name="basic" autoComplete="off" layout={"vertical"}>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="" className="mb-3">
                {" "}
                Certificate Name
                <span className="red">*</span>
              </label>
              <Form.Item>
                <Select
                  placeholder="--Select Application Stauts--"
                  onChange={(e) => setapplication_type(e)}
                >
                  <Select.Option value="learning">Learning DL</Select.Option>
                  <Select.Option value="permanant">Permanent DL</Select.Option>
                  <Select.Option value="renew">Renew DL</Select.Option>
                  <Select.Option value="e_gazzet">e-Gazette</Select.Option>
                  <Select.Option value="foodLicence">
                    Food License
                  </Select.Option>
                  <Select.Option value="CompanyGST">Company GST</Select.Option>
                  <Select.Option value="indGST">Individual GST</Select.Option>
                  <Select.Option value="passport">passport</Select.Option>
                  <Select.Option value="shopAct">Shop-Act</Select.Option>
                  <Select.Option value="voterCard">voter Card</Select.Option>
                  <Select.Option value="udhamAdhar">Udyam-Aadhar</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-4">
              <label htmlFor="" className="mb-3">
                {" "}
                Application Status
                <span className="red">*</span>
              </label>
              <Form.Item>
                <Select
                  placeholder="--Select Application Stauts--"
                  onChange={(e) => {
                    setStatus(e);
                  }}
                >
                  <Select.Option value="IN-PROGRESS">IN-PROGRESS</Select.Option>
                  <Select.Option value="SUBMITTED">SUBMITTED</Select.Option>
                  <Select.Option value="PENDING">PENDING</Select.Option>
                  <Select.Option value="COMPLETE">COMPLETE</Select.Option>
                  <Select.Option value="REJECTED">REJECTED</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-4 mt-2">
              <Form.Item>
                {
                 userRespons.user.istehsil ?(<>
                <div class="d-grid gap-1 d-md-flex justify-content-md-end mt-4 ">
                  <button
                    class="btn "
                    
                    type="button"
                    onClick={() => navigate("/RetailerForm4")}
                    style={{ position: "relative", width: "220px",backgroundColor:"#FF5E0E",color:"white" }}
                  >
                    Tehsil services
                    <span
                      style={{
                        position: "absolute",
                        marginLeft: "5px",
                        marginBottom: "20px",
                      }}
                    >
                      {/* <MdNotificationsActive style={{width:"20px" ,height:"20px" ,marginTop:"5px"}}/> */}
                      <div
                        style={{
                          textAlign: "center",
                          backgroundColor: "red",
                          width: "35px",
                          height: "27px",
                          borderRadius: "10px",
                        }}
                      >
                        {
                          form4SingleUser?.filter((item) => {
                            if (item.isNew && item.status != "IN-PROGRESS") return item;
                          })?.length
                        }
                      </div>
                    </span>
                  </button>
                </div>
                 
                 </>):""
                }
              </Form.Item>
            </div>
            <div className="col-lg-12">
              <h6>
                Search :{" "}
                <span>
                  <input
                    type="text "
                    value={searchdata}
                    onChange={searchByName}
                    style={{
                      border: "1px solid black",
                      borderRadius: "3px",
                    }}
                  />
                </span>
              </h6>

              <div className="row">
                {" "}
                {FilteredData.length > 0 ? (
                  <Table_form11 FilteredData={FilteredData} />
                ) : (
                  <div style={{ marginLeft: "300px", marginTop: "50px" }}>
                    {" "}
                    No found Data
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Retailer_console;
