import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Input, Radio } from "antd";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { incNumber } from "../../Redux/actions";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Breadcrumb from "../common/breadcrumb";

const NewPancard = () => {
    const { lid } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showLoading, setShowLoading] = useState(false);
    const users = useSelector((state) => state.userData.user);
    const userData = JSON.parse(localStorage.getItem("userResponse")) || {};
    const [balance, setBalance] = useState(0);
    const [userFormDetails, setUserFormDetails] = useState([]);

    // Initialize state with fields from the updated mongoose schema
    const [Data, SetData] = useState({
        createdBy: userData?.user?._id || "",
        createdByName: userData?.user?.name || "",
        application_type: "pancard",
        formPrice: 0, // Number
        status: "IN-PROGRESS",
        firstName: "",
        middleName: "",
        lastName: "",
        fathersFullName: "",
        dob: "", // Lowercase
        email: "",
        address: "", // Lowercase
        pincode: "",
        gender: "",
        mobileNo: "", // Adjust if necessary
        note: ""
        // Removed adharCard, signature, photo
    });

    const [docs, setDocs] = useState({
        adharCard: "",
        signature: "",
        photo: ""
    });

    const [formError, setFormError] = useState({});
    const [formFileError, setFormFileError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    // Redirect if user data is missing
    useEffect(() => {
        if (!userData?.user) {
            toast.error("User data is missing. Please log in again.");
            navigate("/login"); // Adjust the path as needed
        }
    }, [userData, navigate]);

    // Fetch formPrice details
    useEffect(() => {
        const fetchFormPrice = async () => {
            try {
                const res = await axios.get(`https://api.maharashtraseva.com/api/formPrice`);
                const response = res.data;

                const filtered = response.filter((item) => item.userID === userData.user._id);
                const userFormDetails = filtered.length === 0
                    ? response.filter((item) => item.userID === "ALL")
                    : filtered;

                setUserFormDetails(userFormDetails);
                const price = userFormDetails[0]?.price || 0;
                SetData((prevData) => ({ ...prevData, formPrice: Number(price) }));
            } catch (err) {
                console.error("Error fetching form price:", err);
                toast.error("Failed to fetch form prices. Please try again later.");
            }
        };
        if (userData.user?._id) {
            fetchFormPrice();
        }
    }, [userData.user?._id]);

    // Fetch user balance
    const reducer = useSelector((state) => state.changeNumber);
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                if (userData.user?._id) {
                    const res = await axios.get(`https://api.maharashtraseva.com/api/recharge`);
                    const allData = res.data.filter((ele) => ele.user === userData.user._id);
                    let amount = 0;
                    let expense = 0;
                    if (allData.length > 0) {
                        allData.forEach((item) => {
                            if (item.isExpence === "true") {
                                expense += item.amount;
                            } else {
                                amount += item.amount;
                            }
                        });
                    }
                    setBalance(amount - expense);
                }
            } catch (err) {
                console.error("Error fetching balance:", err);
                toast.error("Failed to fetch balance. Please try again later.");
            }
        };
        fetchBalance();
    }, [reducer, userData]);

    // Form validation
    const validate = (values) => {
        const errors = {};
        if (!values.firstName) {
            errors.firstName = "First Name is required";
        }
        if (!values.lastName) {
            errors.lastName = "Last Name is required";
        }
        if (!values.dob) {
            errors.dob = "Date of Birth is required";
        }
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = "Email is invalid";
        }
        if (!values.mobileNo) {
            errors.mobileNo = "Mobile Number is required";
        } else if (!/^\d{10}$/.test(values.mobileNo)) {
            errors.mobileNo = "Mobile Number must be 10 digits";
        }
        if (!values.address) {
            errors.address = "Address is required";
        }
        if (!values.pincode) {
            errors.pincode = "Pincode is required";
        } else if (!/^\d{6}$/.test(values.pincode)) {
            errors.pincode = "Pincode must be 6 digits";
        }
        if (!values.fathersFullName) {
            errors.fathersFullName = "Father's Full Name is required";
        }
        if (!values.gender) {
            errors.gender = "Gender is required";
        }
        return errors;
    };

    const fileValidate = (files) => {
        const fileErrors = {};
        if (!files.adharCard) {
            fileErrors.adharCard = "Aadhar Card is required";
        } else if (files.adharCard.size > 1024 * 1024) {
            fileErrors.adharCard = "File size exceeds the limit of 1MB";
        }
        if (!files.signature) {
            fileErrors.signature = "Signature is required";
        } else if (files.signature.size > 500 * 1024) {
            fileErrors.signature = "File size exceeds the limit of 500KB";
        }
        if (!files.photo) {
            fileErrors.photo = "Photo is required";
        } else if (files.photo.size > 500 * 1024) {
            fileErrors.photo = "File size exceeds the limit of 500KB";
        }
        return fileErrors;
    };

    const postLDJData = async (e) => {
        e.preventDefault();
        const fileErrors = fileValidate(docs);
        const errors = validate(Data);

        if (Object.keys(errors).length === 0 && Object.keys(fileErrors).length === 0) {
            setShowLoading(true);
            setIsSubmit(true);
            if (balance > Data.formPrice) {
                try {
                    const { adharCard, signature, photo, ...dataToSend } = Data; // Exclude file fields
                    const res = await axios.post(`https://api.maharashtraseva.com/api/panCard/create`, dataToSend);
                    console.log("POST response:", res.data); // For debugging

                    // Adjust based on the actual response structure
                    const panCardId = res.data?.data?._id || res.data?._id;

                    if (panCardId) {
                        await uploadDocuments(panCardId);
                        resetForm();
                        dispatch(incNumber());
                        navigate("/RetailerDashboard");
                    } else {
                        throw new Error("PAN Card ID not found in the response.");
                    }
                } catch (err) {
                    console.error("Error submitting form:", err);
                    if (err.response) {
                        console.error("Error response data:", err.response.data);
                        toast.error(`Error: ${err.response.data.message || 'There was an error submitting the form. Please try again.'}`);
                    } else if (err.request) {
                        console.error("No response received:", err.request);
                        toast.error("No response from the server. Please try again later.");
                    } else {
                        console.error("Error setting up request:", err.message);
                        toast.error("An unexpected error occurred. Please try again.");
                    }
                    setShowLoading(false);
                }
            } else {
                toast.error("Insufficient balance. Please recharge.");
                setShowLoading(false);
            }
        } else {
            setFormError(errors);
            setFormFileError(fileErrors);
            setShowLoading(false);
            swal("Please fill in the required fields!");
        }
    };

    const uploadDocuments = async (id) => {
        if (!id) {
            console.error("No PAN Card ID provided for document upload.");
            toast.error("Failed to upload documents due to missing PAN Card ID.");
            return;
        }

        const formData = new FormData();
        formData.append("adharCard", docs.adharCard);
        formData.append("signature", docs.signature);
        formData.append("photo", docs.photo);

        try {
            await axios.put(`https://api.maharashtraseva.com/api/panCard/${id}/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Documents Uploaded successfully.");
            toast.success("PAN Card application submitted successfully!");
        } catch (err) {
            console.error("Error uploading documents:", err);
            toast.error("There was an error uploading your documents. Please try again.");
        }
    };

    const resetForm = () => {
        SetData({
            createdBy: userData?.user?._id || "",
            createdByName: userData?.user?.name || "",
            application_type: "pancard",
            formPrice: 0, // Reset to number
            status: "IN-PROGRESS",
            firstName: "",
            middleName: "",
            lastName: "",
            fathersFullName: "",
            dob: "",
            email: "",
            address: "",
            pincode: "",
            gender: "",
            mobileNo: "",
            note: ""
        });
        setDocs({
            adharCard: "",
            signature: "",
            photo: ""
        });
        setFormError({});
        setFormFileError({});
        setIsSubmit(false);
    };

    return (
        <Fragment>
            <Breadcrumb title={"Pan Card"} parent={"license"} />
            <Container fluid={true}>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Form name="basic" autoComplete="off" layout={"vertical"} onFinish={postLDJData}>
                                    <div className="row">
                                        {/* First Name */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> First Name
                                            </label>
                                            <Form.Item
                                                name="firstName"
                                                rules={[{ required: true, message: 'First Name is required' }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter First Name"
                                                    value={Data.firstName}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, firstName: e.target.value })
                                                    }
                                                />
                                                <p className="red">{formError.firstName}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Middle Name */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">Middle Name</label>
                                            <Form.Item name="middleName">
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Middle Name"
                                                    value={Data.middleName}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, middleName: e.target.value })
                                                    }
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* Last Name */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Last Name
                                            </label>
                                            <Form.Item
                                                name="lastName"
                                                rules={[{ required: true, message: 'Last Name is required' }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Last Name"
                                                    value={Data.lastName}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, lastName: e.target.value })
                                                    }
                                                />
                                                <p className="red">{formError.lastName}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Father's Full Name */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Father's Full Name
                                            </label>
                                            <Form.Item
                                                name="fathersFullName"
                                                rules={[{ required: true, message: "Father's Full Name is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Father's Full Name"
                                                    value={Data.fathersFullName}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, fathersFullName: e.target.value })
                                                    }
                                                />
                                                <p className="red">{formError.fathersFullName}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Date of Birth */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Date of Birth
                                            </label>
                                            <Form.Item
                                                name="dob"
                                                rules={[{ required: true, message: "Date of Birth is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    type="date"
                                                    placeholder="Enter Date of Birth"
                                                    value={Data.dob}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, dob: e.target.value })
                                                    }
                                                />
                                                <p className="red">{formError.dob}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Gender */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Gender
                                            </label>
                                            <Form.Item
                                                name="gender"
                                                rules={[{ required: true, message: "Gender is required" }]}
                                            >
                                                <Radio.Group
                                                    value={Data.gender}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, gender: e.target.value })
                                                    }
                                                >
                                                    <Radio value="male">Male</Radio>
                                                    <Radio value="female">Female</Radio>
                                                    <Radio value="other">Other</Radio>
                                                </Radio.Group>
                                                <p className="red">{formError.gender}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Mobile Number */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Mobile Number
                                            </label>
                                            <Form.Item
                                                name="mobileNo"
                                                rules={[
                                                    { required: true, message: "Mobile Number is required" },
                                                    { pattern: /^\d{10}$/, message: "Mobile Number must be 10 digits" }
                                                ]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Mobile Number"
                                                    value={Data.mobileNo}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, mobileNo: e.target.value })
                                                    }
                                                />
                                                <p className="red">{formError.mobileNo}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Email */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Email
                                            </label>
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    { required: true, message: "Email is required" },
                                                    { type: "email", message: "Email is invalid" }
                                                ]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Email"
                                                    value={Data.email}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, email: e.target.value })
                                                    }
                                                />
                                                <p className="red">{formError.email}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Address */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Address
                                            </label>
                                            <Form.Item
                                                name="address"
                                                rules={[{ required: true, message: "Address is required" }]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Address"
                                                    value={Data.address}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, address: e.target.value })
                                                    }
                                                />
                                                <p className="red">{formError.address}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Pincode */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Pincode
                                            </label>
                                            <Form.Item
                                                name="pincode"
                                                rules={[
                                                    { required: true, message: "Pincode is required" },
                                                    { pattern: /^\d{6}$/, message: "Pincode must be 6 digits" }
                                                ]}
                                            >
                                                <Input
                                                    className="p-2"
                                                    placeholder="Enter Pincode"
                                                    value={Data.pincode}
                                                    onChange={(e) =>
                                                        SetData({ ...Data, pincode: e.target.value })
                                                    }
                                                />
                                                <p className="red">{formError.pincode}</p>
                                            </Form.Item>
                                        </div>

                                        {/* Document Uploads */}
                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Aadhar Card Upload
                                            </label>
                                            <Form.Item
                                                name="adharCard"
                                                rules={[{ required: true, message: "Aadhar Card is required" }]}
                                            >
                                                <Input
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                    onChange={(e) =>
                                                        setDocs({ ...docs, adharCard: e.target.files[0] })
                                                    }
                                                />
                                                <p className="red">{formFileError.adharCard}</p>
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Signature Upload
                                            </label>
                                            <Form.Item
                                                name="signature"
                                                rules={[{ required: true, message: "Signature is required" }]}
                                            >
                                                <Input
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                    onChange={(e) =>
                                                        setDocs({ ...docs, signature: e.target.files[0] })
                                                    }
                                                />
                                                <p className="red">{formFileError.signature}</p>
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <label className="mb-3 fs-6 fw-bolder">
                                                <span className="red">*</span> Photo Upload
                                            </label>
                                            <Form.Item
                                                name="photo"
                                                rules={[{ required: true, message: "Photo is required" }]}
                                            >
                                                <Input
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                    onChange={(e) =>
                                                        setDocs({ ...docs, photo: e.target.files[0] })
                                                    }
                                                />
                                                <p className="red">{formFileError.photo}</p>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <Button type="primary" htmlType="submit" loading={showLoading}>
                                        Submit
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default NewPancard;
