import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  Chrome,
  List,
  BarChart,
  Settings,
  Archive,
  LogIn,
  LogOut,
  MapPin,
  CreditCard,
  Percent,
  Calendar,
  Search,
  Menu
} from "react-feather";
import { BookOutlined } from "@ant-design/icons";
const userData = JSON.parse(localStorage.getItem('userResponse'))

export const MENUITEMS = [
  {
    path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
  },
  {
    path: '/admin_application', title: 'Application Console', icon: Search, type: 'link', badgeType: 'primary', active: false
  },




  {
    title: "User Managment",
    icon: Menu,
    type: "sub",
    active: false,
    children: [
      { path: "/users/create-user", title: "Create User", type: "link" },
      { path: "/users/list-user", title: " User Details", type: "link" },
      { path: "/users/changePassword", title: " Change User Password", type: "link" },
      { path: "/users/recharge", title: "Recharge", type: "link" },
      { path: "/allFormPrice", title: "Form Price Update", type: "link" },
      { path: "/users/addlink", title: "Add Links", type: "link" },
      { path: "/AddYoutubeLinks", title: "Add Youtube Links", type: "link" },
      { path: "/AddMemberKit", title: "Add Member Kit", type: "link" },
      { path: "/Corosol1", title: "Corosul1", type: "link" },
      { path: "/Corosol2", title: "Corosul2", type: "link" },
      { path: "/Corosol3", title: "Corosul3", type: "link" },
      { path: "/AddOurTeam", title: "Add Our Team", type: "link" },
      // { path: "/users/create-user", title: "Create User", type: "link" },
    ],
  },
  // {
  //   title: "Report",
  //   icon: Menu,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/Recharge_Histry", title: "Recharge Histry", type: "link" },
  //     { path: "/reports/applicationstat", title: "Application Status", type: "link" },
  //     // { path: "/reports/applicationstat", title: "Application Status", type: "link" },
  //     // { path: "/reports/enquire", title: "Enquires", type: "link" },


  //   ],
  // },
  {
    title: "Report",
    icon: Menu,
    type: "sub",
    active: false,
    children: [
      { path: "/Recharge_Histry", title: "Recharge Histry", type: "link" },
      // { path: "/Price/create", title: "Application Status", type: "link" },
      { path: "/PanCoupans", title: "Pan Coupans ", type: "link" },
      // { path: "/reports/enquire", title: "Enquires", type: "link" },


    ],
  },

  {
    title: "Agent Requests",
    icon: Menu,
    type: "sub",
    active: false,
    children: [
      { path: "/agentRequest", title: "Withdraw Request", type: "link" },



    ],
  },
  // {
  //   title: "Setting",
  //   icon: Menu,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/users/list-user", title: "All Requests", type: "link" },



  //   ],
  // },
  {
    title: "Software",
    icon: Menu,
    type: "sub",
    active: false,
    children: [
      { path: "https://anydesk.com/en/downloads/windows", title: "Any Desk", type: "link" },
      { path: "https://www.winzip.com/en/", title: "Win-Zip", type: "link" },
      { path: "https://google-input-marathi.software.informer.com/", title: "Marathi Typping", type: "link" },
    ],
  },

];
export const RETAILERMENUITEM = [
  {
    path: '/RetailerDashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
  },
  {
    path: '/retailer_console', title: 'Application Console', icon: Search, type: 'link', badgeType: 'primary', active: false
  },
  {
    title: "G2C Services",
    icon: Menu,
    type: "sub",
    active: false,
    children: [
      {

        title: "Driving Licence",
        type: "sub",
        children: [
          {
            path: "/drivingLicence/learningDl",
            title: "Learning Dl",
            type: "link",
          },
          {
            path: "/drivingLicence/permanantDl",
            title: "Permanant Dl",
            type: "link",
          },
          {
            path: "/drivingLicence/RenewDl",
            title: "Renew Dl",
            type: "link",
          },
        ]
      },
      {
        path: "/egazette/create",
        title: "E-Gazette",
        type: "link",
      },

      {
        path: "/FoodLicence/create",
        title: "Food Licence",
        type: "link",
      },
      {
        // path: "/products/digital/digital-product-list",
        title: "Gst Registration",
        type: "sub",
        children: [
          {
            path: "/Company_Gst/create",
            title: "Company GST",
            type: "link",
          },
          {
            path: "Individual_Gst/create",
            title: "Individual GST",
            type: "link",
          },

        ]
      },
      {
        path: "/products/digital/digital-product-list",
        title: "PAN Card-UTI",
        type: "sub",
        children: [
          {
            path: "/products/digital/digital-sub-category",
            title: "PAN Card",
            type: "link",
          },
          {
            path: "/PanCoupans",
            title: "Coupan Request",
            type: "link",
          },

        ]
      },
      {
        path: "/products/digital/digital-product-list",
        title: "PAN Card",
        type: "sub",
        children: [
          {
            path: "/newpan",
            title: " New Pan",
            type: "link",
          },
          {
            path: "/correction",
            title: "CorrectionPan",
            type: "link",
          },

        ]
      },
      {
        path: "/PassportForm/create",
        title: "Passport",
        type: "link",
      },
      {
        path: "/ShopActForm/create",
        title: "Shop Act",
        type: "link",
      },
      {
        path: "/UdhamAbhar/create",
        title: "Udyam Aadhar",
        type: "link",
      },
      {
        path: "/VoterCard/create",
        title: "Voter Card",
        type: "link",
      },


      {

        title: "Tahsil Services",
        type: "sub",
        children: [
          {
            path: "/income",
            title: "Income Certificate",
            type: "link",
          },
          {
            path: "/age_nationality",
            title: "Age Domicile Certificate",
            type: "link",
          },
          {
            path: "non_cremyLayer/",
            title: "Non Creamy Layer Certificate",
            type: "link",
          },
          {
            path: "ews/",
            title: "EWS Certificate",
            type: "link",
          },
        ]
      },

    ],
  },
  {
    title: "Other Services",
    icon: Menu,
    type: "sub",
    active: false,


  },
  {
    title: "Report",
    icon: Menu,
    type: "sub",
    active: false,
    children: [
      { path: "/Recharge_Histry", title: "Recharge Histry", type: "link" },
      { path: "/AppStatics", title: "Application Status", type: "link" },
      // { path: "/reports/enquire", title: "Enquires", type: "link" },
    ],
  },
  {
    title: "Software",
    icon: Menu,
    type: "sub",
    active: false,
    children: [
      { path: "https://anydesk.com/en/downloads/windows", title: "Any Desk", type: "link" },
      { path: "https://www.winzip.com/en/", title: "Win-Zip", type: "link" },
      { path: "https://google-input-marathi.software.informer.com/", title: "Marathi Typping", type: "link" },
    ],
  },

];

export const Agent = [

  {
    path: '/AgentDashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
  },
  {
    path: '/agent/application_Console', title: 'Application Console', icon: Search, type: 'link', badgeType: 'primary', active: false
  },
  {
    path: '/agentRequest', title: 'Creadit Reports', icon: Search, type: 'link', badgeType: 'primary', active: false
  }

];

