import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { incNumber } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { Form, Select } from "antd";
import { toast } from "react-toastify";

function PanAccept() {
  const dispatch = useDispatch();
  const [coupons, setcoupans] = useState();
  const reducer = useSelector((state) => state.changeNumber);
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const [Data, setData] = useState();
  const [AllData, setAllData] = useState();
  const [startDate, setStartDate] = useState(""); // State to store start date
  const [endDate, setEndDate] = useState("");
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  useEffect(() => {
    axios
      .get(`https://api1.mhseva.com/api/panUti`)
      .then((res) => {
        const response = res.data.reverse();
        if (userData.user.role === "retailer") {
          const filter = response.filter(
            (item) => item.retailerName === userData.user.name
          );
          setAllData(filter);
          setcoupans(filter);
        } else {
          // const filter = response.filter((item) => item.isAccept === "false");
          setAllData(response);
          setcoupans(response);
        }
      })
      .catch((err) => console.log(32, err));
  }, [reducer]);
  const requestAccept = (id) => {
    const getOBj = coupons.filter((item) => item._id === id);
    const obj = {
      NO_coupons: getOBj[0].NO_coupons,
      couponType: getOBj[0].couponType,

      isAccept: true,

      panType: getOBj[0].panType,

      totalAmount: getOBj[0].totalAmount,
      isNewPan: false,
    };
    axios
      .put(`https://api1.mhseva.com/api/panUti/${id}`, obj)
      .then((res) => {
        dispatch(incNumber());
        toast.success("Request Accept SucceFully....")
      })
      .catch((err) => console.log(err));
  };
  const columns = [
    {
      name: "Sr NO",
      width: "80px",
      style: {
        textAlign: "center",
        fontWaight: "700",
      },
      selector: (row, index) => index + 1,
    },
    {
      name: "Coupan Type ",
      width: "190px",
      style: {
        textAlign: "center",
        fontWaight: "700",
      },
      selector: (row) => row?.couponType,
    },
    {
      name: "Retailer Name",
      width: "190px",
      style: {
        textAlign: "center",
        fontWaight: "700",
      },
      selector: (row) => row?.retailerName,
    },
    {
      name: "No Of Coupon ",
      width: "160px",
      style: {
        textAlign: "center",
        fontWaight: "700",
      },
      selector: (row) => row?.NO_coupons,
    },
    {
      name: "Pan Type",
      width: "190px",
      style: {
        textAlign: "center",
      },
      selector: (row) => row?.panType,
    },
    {
      name: "Status",
      style: {
        textAlign: "center",
        fontWaight: "800",
      },
      selector: (row) => (row?.isAccept === "false" ? "Pending" : "Accepted"),
    },
    {
      name: "Total Amount",
      width: "130px",
      style: {
        textAlign: "center",
      },
      selector: (row) => row?.totalAmount,
    },
    {
      name: "Transaction ID",
      width: "200px",
      style: {
        textAlign: "center",
      },
      selector: (row) => row?.transactionID,
    },
    {
      name: "Created Date",
      width: "180px",
      style: {
        textAlign: "center",
      },
      selector: (row) => GetDate(row?.createdAt),
    },
    {
      name: "Accept Coupans",
      width: "180px",
      style: {
        textAlign: "center",
      },
      selector: (row) => (
        <>
          {row.isAccept === "false" ? (
            <>
              <i
                class="bi bi-check2-circle fs-3"
                onClick={(e) => {
                  requestAccept(row._id);
                }}
              ></i>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];
  const updatedColumns =
    userData.user.role === "retailer"
      ? columns.filter((col) => col.name !== "Accept Coupans") // Retailer logged in, keep the column
      : [...columns];

  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWeight: "600",
        fontSize: "18px",
        //  width:"fit-content"
        // Add any other CSS properties you want to apply to the rows
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        border: "1px solid black",
        backgroundColor: "#0094ef  ",
        color: "white",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        border: "1px solid black",
        width: "fit-content"
      },
    },
    cells: {
      style: {
        // Add any styles for the regular cells
        border: "1px solid black",
        width: "fit-content"
      },
    },
  };
  const searchByDateRange = () => {
    let credit = 0;
    const filtered = AllData.filter((item) => {
      const createdDate = new Date(item.createdAt);
      return (
        createdDate >= new Date(startDate) && createdDate <= new Date(endDate)
      );
    });
    // setFilter(filtered);
    setcoupans(filtered);
  };
  return (
    <div>
      <div className="container">
        <Breadcrumb title="Coupan requests" parent="applicaSta" />

        {userData.user.role === "admin" ? (
          <>
            <div className="col-md-4">
              <label htmlFor="" className="mb-3">
                {" "}
                Application Status
                <span className="red">*</span>
              </label>
              <Form.Item>
                <Select
                  placeholder="--Select Application Stauts--"
                  onChange={(e) => {
                    if (e == "ALL") {
                      console.log(31, "All");
                      setcoupans(AllData);
                    } else if (e == "PENDING") {
                      const pendding = AllData.filter(
                        (item) => item.isAccept == "false"
                      );
                      setcoupans(pendding);
                      console.log(31, "PENDING");
                    } else if (e == "ACCEPTED") {
                      // console.log("ok")
                      const FilterData = AllData.filter(
                        (item) => item.isAccept === "true"
                      );
                      setcoupans(FilterData);
                      console.log(31, "ACCEPTED");
                    }
                  }}
                >
                  <Select.Option value="ALL">ALL</Select.Option>
                  <Select.Option value="PENDING">PENDING</Select.Option>
                  <Select.Option value="ACCEPTED">ACCEPTED</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </>
        ) : (
          <>
            {/* <div className="col-md-4">
            <label htmlFor="" className="mb-3">
              {" "}
              Application Status
              <span className="red">*</span>
            </label>
            <Form.Item>
              <Select
                placeholder="--Select Application Stauts--"
                onChange={(e) => SetStatus(e)}
              >
           
                <Select.Option value="PENDING">PENDING</Select.Option>
                <Select.Option value="ACCEPTED">ACCEPTED</Select.Option>
              </Select>
            </Form.Item>
          </div> */}
            <div className="col-md-4">
              <label htmlFor="" className="mb-3">
                {" "}
                Application Status
                <span className="red">*</span>
              </label>
              <Form.Item>
                <Select
                  placeholder="--Select Application Stauts--"
                  onChange={(e) => {
                    if (e == "ALL") {
                      console.log(31, "All");
                      setcoupans(AllData);
                    } else if (e == "PENDING") {
                      const pendding = AllData.filter(
                        (item) => item.isAccept == "false"
                      );
                      setcoupans(pendding);
                      console.log(31, "PENDING");
                    } else if (e == "ACCEPTED") {
                      // console.log("ok")
                      const FilterData = AllData.filter(
                        (item) => item.isAccept === "true"
                      );
                      setcoupans(FilterData);
                      console.log(31, "ACCEPTED");
                    }
                  }}
                >
                  <Select.Option value="ALL">ALL</Select.Option>
                  <Select.Option value="PENDING">PENDING</Select.Option>
                  <Select.Option value="ACCEPTED">ACCEPTED</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </>
        )}
        <div className="row rext center m-4">
          <div className="col-lg-4">
            <input
              type="date"
              class="form-control"
              id="inputEmail4"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="col-lg-4">
            <input
              type="date"
              placeholder="End Date"
              class="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="col-lg-4">
            <button
              onClick={searchByDateRange}
              className="btn btn-outline-primary"
            >
              Search
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mt-5">
            <DataTable
              columns={updatedColumns}
              data={coupons}
              pagination
              highlightOnHover
              fixedHeader
              fixedHeaderScrollHeight="600px"
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PanAccept;
