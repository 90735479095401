import React, { Fragment, useState } from "react";
import Breadcrumb from "./common/breadcrumb";
import {
  Navigation,
  Box,
  MessageSquare,
  Users,
  Briefcase,
  CreditCard,
  ShoppingCart,
  Calendar,
  Archive,
  AlignRight,
} from "react-feather";
import ReactPlayer from "react-player";
import { useRef } from "react";
import CountUp from "react-countup";
import { Chart } from "react-google-charts";
import salary from "../assets/images/salary.png";
import Maharashtra from "../assets/images/maharashtra.png";
import { Bar, Line, Pie } from "react-chartjs-2";
import Note from "../assets/images/notebook.gif";
import Form from "../assets/images/document.gif";
import Earning from "../assets/images/money-bag.gif";
import Income from "../assets/images/income.gif";
import Complete from "../assets/images/infography.gif";
import Pending from "../assets/images/infography.gif";
import member from "../assets/images/society.gif";
import chart from "../assets/images/line-chart.gif";
// import l1 from "../assets/images/"
import {
  lineOptions,
  buyOption,
  employeeData,
  employeeOptions,
} from "../constants/chartData";
import kit from "../assets/images/member.png";
import Info from "../assets/images/information.gif";
import money from "../assets/images/manoy.gif";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  ArcElement,
  Filler,
  RadialLinearScale,
} from "chart.js";
import Whatsapp from "../assets/images/whatsapp1.png";
// image impoer
import user2 from "../assets/images/dashboard/user2.jpg";
import user1 from "../assets/images/dashboard/user1.jpg";
import man from "../assets/images/dashboard/man.png";
import user from "../assets/images/dashboard/user.png";
import designer from "../assets/images/dashboard/designer.jpg";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloudFilled } from "@ant-design/icons";
// import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { useEffect } from "react";
import axios from "axios";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  ArcElement,
  Filler,
  RadialLinearScale
);

const Dashboard = () => {
  const navigate = useNavigate();
  const form4 = useSelector((state) => state.formFour.form4);
  const formEle = useSelector((state) => state.formeleven.formEle);

  const [LearningDL, seLearningDL] = useState([])
  const [ParmenanrDL, seParmenanrDL] = useState([])
  const [ReneDL, seReneDL] = useState([])
  const [VoterCard, seVoterCard] = useState([])
  const [UdhamAdhar, seUdhamAdhar] = useState([])
  const [FoodLineces, seFoodLineces] = useState([])
  const [CompGST, seCompGST] = useState([])
  const [IndGST, seIndGST] = useState([])
  const [E_Gazette, seE_Gazette] = useState([])
  const [Passport, sePassport] = useState([])
  const [ShopAct, seShopAct] = useState([])
  const [Income, seIncome] = useState([])
  const [AgeDomiclie, seAgeDomiclie] = useState([])
  const [EWS, seEWS] = useState([])
  const [NonCreamly, seNonCreamly] = useState([])
  const [pendingForms, SetPendingForms] = useState()
  const [CompleteForms, SetCompleteForms] = useState()
  const getAllData = () => {
    const pendin11 = formEle.filter((item) => item.status === "IN-PROGRESS")
    const pendin4 = form4.filter((item) => item.status === "IN-PROGRESS")
    SetPendingForms(pendin11.length + pendin4.length);
    const Complete11 = formEle.filter((item) => item.status === "COMPLETE")
    const Complete4 = form4.filter((item) => item.status === "COMPLETE")
    SetCompleteForms(Complete11.length + Complete4.length);
    const learning = formEle.filter((item) => item.application_type == "learning" && item.status === "IN-PROGRESS")
    seLearningDL(learning);
    const permanat = formEle.filter((item) => item.application_type == "permanant" && item.status === "IN-PROGRESS")
    seParmenanrDL(permanat);
    const reneDL = formEle.filter((item) => item.application_type == "renew" && item.status === "IN-PROGRESS")
    seReneDL(reneDL);
    const voterCard = formEle.filter((item) => item.application_type == "voterCard" && item.status === "IN-PROGRESS")
    seVoterCard(voterCard);
    const udhamAdhar = formEle.filter((item) => item.application_type == "udhamAdhar" && item.status === "IN-PROGRESS")
    seUdhamAdhar(udhamAdhar);
    const foodLineces = formEle.filter((item) => item.application_type == "foodLicence" && item.status === "IN-PROGRESS")
    seFoodLineces(foodLineces);
    const compGST = formEle.filter((item) => item.application_type == "CompanyGST" && item.status === "IN-PROGRESS")
    seCompGST(compGST);
    const indGST = formEle.filter((item) => item.application_type == "indGST" && item.status === "IN-PROGRESS")
    seIndGST(indGST);
    const e_Gazette = formEle.filter((item) => item.application_type == "e_gazzet" && item.status === "IN-PROGRESS")
    seE_Gazette(e_Gazette);
    const passport = formEle.filter((item) => item.application_type == "passport" && item.status === "IN-PROGRESS")
    sePassport(passport);
    const shopAct = formEle.filter((item) => item.application_type == "shopAct" && item.status === "IN-PROGRESS")
    seShopAct(shopAct);
    const income = form4.filter((item) => item.application_type == "Income certificate" && item.status === "IN-PROGRESS")
    seIncome(income);
    const ageDomiclie = form4.filter((item) => item.application_type == "Age_Nationality" && item.status === "IN-PROGRESS")
    seAgeDomiclie(ageDomiclie);
    const eWS = form4.filter((item) => item.application_type == "EWS" && item.status === "IN-PROGRESS")
    seEWS(eWS);
    const nonCreamly = form4.filter((item) => item.application_type == "Non_Cremylayer certificate" && item.status === "IN-PROGRESS")
    seNonCreamly(nonCreamly);
  }
  useEffect(() => {
    getAllData()
  }, [form4, formEle])

  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  const totalRevenue = (data) => {
    var total = 0;
    data.forEach((element) => {
      total += element.totalPrice;
    });
    return total;
  };
  const monthLater = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateSeter2 = (date) => {
    const d = new Date(date);
    const month = d.getMonth();
    return `${monthLater[month]} `;
  };
  const dateSeter3 = (date) => {
    const d = new Date(date);
    const month = d.getMonth();
    return `${monthLater[month - 1]} `;
  };
  const currentMonthSale = (data) => {
    if (data) {
      const today = new Date();

      const filter = data.filter(
        (data) => dateSeter2(data.createdAt) === dateSeter2(today)
      );
      let total = 0;
      filter.forEach((element) => {
        total += element.totalPrice;
      });
      return total;
    }
  };
  const lastMonthSale = (data) => {
    if (data) {
      const today = new Date();

      const filter = data.filter(
        (data) => dateSeter2(data.createdAt) === dateSeter3(today)
      );
      let total = 0;
      filter.forEach((element) => {
        total += element.totalPrice;
      });
      return total;
    }
  };
  const currentMonthHotelsActive = (data) => {
    const today = new Date();

    const filter = data.filter((data) => {
      if (data.isActive == true) {
        if (dateSeter2(data.updatedAt) === dateSeter2(today)) {
          return data;
        }
      }
    });
    return filter.length;
  };
  const lastMonthHotelsActive = (data) => {
    const today = new Date();

    const filter = data.filter((data) => {
      if (data.isActive == true) {
        if (dateSeter2(data.updatedAt) === dateSeter3(today)) {
          return data;
        }
      }
    });
    return filter.length;
  };
  const lineData = {
    labels: ["100", "200", "300", "400", "500", "600", "700", "800"],
    datasets: [
      {
        data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
        borderColor: "#ff8084",
        backgroundColor: "#ff8084",
        borderWidth: 2,
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
      //   {
      //     data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
      //     borderColor: "#a5a5a5",
      //     backgroundColor: "#a5a5a5",
      //     borderWidth: 2,
      //     barPercentage: 0.7,
      //     categoryPercentage: 0.4,
      //   },
    ],
  };

  const buyData = {
    labels: ["", "10", "20", "30", "40", "50"],
    datasets: [
      {
        backgroundColor: "transparent",
        borderColor: "#13c9ca",
        data: [20, 5, 80, 10, 100, 15],
        lineTension: 0.4,
      },
      {
        backgroundColor: "transparent",
        borderColor: "#a5a5a5",
        data: [0, 50, 20, 70, 30, 27],
        lineTension: 0.4,
      },
      {
        backgroundColor: "transparent",
        borderColor: "#ff8084",
        data: [0, 30, 40, 10, 86, 40],
        lineTension: 0.4,
      },
    ],
  };

  const doughnutOptions = {
    title: "",
    pieHole: 0.35,
    pieSliceBorderColor: "none",
    colors: ["#ff8084", "#13c9ca", "#a5a5a5"],
    legend: {
      position: "none",
    },
    pieSliceText: "none",
    tooltip: {
      trigger: "none",
    },
    animation: {
      startup: true,
      easing: "linear",
      duration: 1500,
    },
    chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
    enableInteractivity: false,
  };
  const pieOptions = {
    title: "",
    pieHole: 1,
    slices: [
      {
        color: "#ff8084",
      },
      {
        color: "#13c9ca",
      },
      {
        color: "#f0b54d",
      },
    ],
    tooltip: {
      showColorCode: false,
    },
    chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
    legend: "none",
  };
  const LineOptions = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#ff8084"],
    legend: "none",
  };
  const LineOptions1 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#13c9ca"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };
  const LineOptions2 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#f5ce8a"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };
  const LineOptions3 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#a5a5a5"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };
  const dateSeter = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    return `${day} ${monthLater[month]} ${year}`;
  };
  function getPercent(x, y) {
    if (x > y) {
      const z = Math.floor(x - y);
      return (z / x) * 100;
    } else {
      const z = Math.floor(y - x);
      return (z / x) * 100;
    }
  }

  // ----------------------------chart --------------------------------
  const data = {
    textAlign: "center",
    labels: [

      ["LearningDL", LearningDL?.length],
      ["ParmenanrDL", ParmenanrDL.length],
      ["ReneDL", ReneDL.length],
      ["VoterCard", VoterCard.length],
      ["UdhamAdhar", UdhamAdhar.length],
      ["FoodLineces", FoodLineces.length],
      ["CompGST", CompGST.length],
      ["IndGST", IndGST.length],
      ["E_Gazette", E_Gazette.length],
      ["Passport", Passport.length],
      ["ShopAct", ShopAct.length],
      ["Income", Income.length],
      ["AgeDomiclie", AgeDomiclie.length],
      ["EWS", EWS.length],
      ["NonCreamly", NonCreamly.length],
    ],

    datasets: [
      {
        label: "Data",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
        backgroundColor: [
          "rgb(120,52,144)",
          "rgb(68,48,140)",
          "rgb(60,91,166)",
          "rgb(19,144,201)",
          "rgb(95,171,68)",
          "rgb(201,214,52)",
          "rgb(235,228,56)",
          "rgb(241,183,23)",
          "rgb(241,150,29)",
          "rgb(233,81,35)",
          "rgb(205,85,126)",
          "rgb(232,142,152)",
          "rgb(221,162,153)",
          "rgb(240,208,198)",
        ],
        borderColor: [
          "rgb(120,52,144)",
          "rgb(68,48,140)",
          "rgb(60,91,166)",
          "rgb(19,144,201)",
          "rgb(95,171,68)",
          "rgb(201,214,52)",
          "rgb(235,228,56)",
          "rgb(241,183,23)",
          "rgb(241,150,29)",
          "rgb(233,81,35)",
          "rgb(205,85,126)",
          "rgb(232,142,152)",
          "rgb(221,162,153)",
          "rgb(240,208,198)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "rgb(234,237,247)", // Set the color for the y-axis labels
        },
        grid: {
          color: "rgba(126,127,132,0.3)", // Set the color for the y-axis grid lines
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          // color: 'rgb(234,237,247)', // Set the color for the y-axis labels
        },
        grid: {
          color: "rgba(126,127,132,0.1)", // Set the color for the y-axis grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
        },
      },
    },
  };
  // 	const VIDEO_PATH = 'https://youtu.be/0BIaDVnYp2A';
  // const playerRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [closePopup, setclosePopup] = useState(false);

  const PIdata = [
    ["Task", "Hours per Day"],
    ["LearningDL", LearningDL?.length],
    ["ParmenanrDL", ParmenanrDL.length],
    ["ReneDL", ReneDL.length],
    ["VoterCard", VoterCard.length],
    ["UdhamAdhar", UdhamAdhar.length],
    ["FoodLineces", FoodLineces.length],
    ["CompGST", CompGST.length],
    ["IndGST", IndGST.length],
    ["E_Gazette", E_Gazette.length],
    ["Passport", Passport.length],
    ["ShopAct", ShopAct.length],
    ["Income", Income.length],
    ["AgeDomiclie", AgeDomiclie.length],
    ["EWS", EWS.length],
    ["NonCreamly", NonCreamly.length],
  ];
  const PIoptions = {
    title: "My Forms Records",

    is3D: true,
  };
  const [todyRecharge, setTodayRecharge] = useState();
  const [totalRecharge, setTotalRecharge] = useState();
  useEffect(() => {
    const today = new Date();
    const day = today.getDate();
    console.log(519, day)
    axios
      .get(`https://api.maharashtraseva.com/api/recharge`)
      .then((res) => {
        const response = res.data.reverse();
        const alldata = response.filter((item) => item.isExpence == "false");
        // setFilter(alldata);
        // // console.log(24,alldata)
        let todayrecharge = 0;
        if (alldata.length > 0) {
          alldata.forEach((item) => {
            if (item.isExpence === "false" && dateSeter(item.createdAt) === dateSeter(today)) {
              if (item.amount !== "" && item.amount !== undefined) {
                todayrecharge += item.amount;
              }
            }
          });
          setTodayRecharge(todayrecharge);
        }
        let Totatrecharge = 0;
        if (alldata.length > 0) {
          alldata.forEach((item) => {
            if (item.isExpence === "false") {
              if (item.amount !== "" && item.amount !== undefined) {
                Totatrecharge += item.amount;
              }
            }
          });
          setTotalRecharge(Totatrecharge);
        }
      })
      .catch((err) => console.log(40, err));
    const hasShownPopup = localStorage.getItem("hasShownPopup");
    if (!hasShownPopup) {
      setShowPopup(true);
      localStorage.setItem("hasShownPopup", true);
    }
  }, []);
  return (
    <Fragment>

      <Breadcrumb title="ADMIN" parent="ADMIN" />

      <div id="datatable"></div>
      <Container fluid={true}>
        <Row >
          <Col
            xl="3 xl-50"
            md="6"
            onClick={() => navigate(`${process.env.PUBLIC_URL}/notice`)}
            style={{ cursor: "pointer" }}
          >
            <Card className=" o-hidden widget-cards ">
              <CardBody className="" style={{ borderRadius: "none" }}>
                <Media className="static-top-widget row">
                  <div class=""></div>
                  <div className="icons-widgets col-4 rounded-8">
                    <div className="align-self-center text-center">
                      <img className="App-logo" src={Note} alt="" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="fs-6 fw-semibold" style={{ color: "black" }}>
                      Notice Board
                    </span>
                    <h3 className="mb-0"></h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3 xl-50" md="6">
            <Card className=" o-hidden  widget-cards">
              <CardBody className=" " style={{ borderRadius: "none" }}>
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <img className="App-logo" src={Info} alt="" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <a href="https://wa.me/917507173568">
                      <span className="fs-6 fw-semibold" style={{ color: "black" }}>
                        Need Help
                      </span>
                    </a>
                    <h3 className="mb-0"></h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col
            xl="3 xl-50"
            md="6"
            // onClick={() => navigate(`${process.env.PUBLIC_URL}/Trainning`)}
            style={{ cursor: "pointer" }}
          >
            <Card className="o-hidden widget-cards">
              <CardBody className="" style={{ borderRadius: "none" }}>
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <img className="App-logo" src={Form} alt="" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="fs-6 fw-semibold" style={{ color: "black" }}>
                      Training Video
                    </span>
                    <h3 className="mb-0"></h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3 xl-50" md="6">
            <Card className=" o-hidden widget-cards">
              <CardBody className="" style={{ borderRadius: "none" }}>
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <img className="App-logo" src={Earning} alt="" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="fs-6 fw-semibold" style={{ color: "black" }}>
                      Total Work
                    </span>
                    <h3 className="mb-0">
                      <CountUp className="counter" end={CompleteForms} />
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>

          <Col xl="3 xl-50" md="6">
            {/* <img src={Maharashtra} className="lo1" alt=""/> */}

            <Card className=" o-hidden widget-cards">
              <CardBody className="" style={{ borderRadius: "none" }}>
                <Media className="static-top-widget row">
                  <div class=""></div>
                  <div className="icons-widgets col-4 rounded-8">
                    <div className="align-self-center text-center">
                      <img className="App-logo" src={Earning} alt="" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="fs-6 fw-semibold" style={{ color: "black" }}>
                      Today Recharge
                    </span>
                    <h3 className="mb-0">
                      ₹ <CountUp className="counter" end={todyRecharge} />
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3 xl-50" md="6">
            <Card className=" o-hidden  widget-cards">
              <CardBody className="" style={{}}>
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <img className="App-logo" src={Complete} alt="" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="fs-6 fw-semibold" style={{ color: "black" }}>
                      Total Recharge
                    </span>
                    <h3 className="mb-0">
                      <CountUp className="counter" end={totalRecharge} />
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3 xl-50" md="6">
            <Card className="o-hidden widget-cards">
              <CardBody className="" style={{ borderRadius: "none" }}>
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <img className="App-logo" src={chart} alt="" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="fs-6 fw-semibold" style={{ color: "black" }}>
                      Pending Form
                    </span>
                    <h3 className="mb-0">
                      <CountUp className="counter" end={pendingForms} />
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3 xl-50" md="6">
            <Card className=" o-hidden widget-cards">
              <CardBody className="" style={{ borderRadius: "none" }}>
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <img className="App-logo" src={chart} alt="" />
                    </div>
                  </div>
                  <Media
                    body
                    className="col-8"
                    // onClick={() =>
                    //   navigate(`${process.env.PUBLIC_URL}/memberkit`)
                    // }
                    style={{ cursor: "pointer" }}
                  >
                    <span className="fs-6 fw-semibold" style={{ color: "black" }}>
                      Member-kit
                    </span>
                    <h3 className="mb-0">
                      <CountUp className="counter" end={100} />
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>

          <div class="row mt-2 px-14 ">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body" style={{}}>
                  <div class="row">
                    <div class="col-md-6 col-sm-6 deskShow">
                      <p class="text-center">
                        <strong
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontFamily: "-moz-initial",
                          }}
                        >
                          {" "}
                          Application Statistics
                        </strong>
                      </p>

                      {/* <img src={Pie} className="pie" alt="" style={{ width: "100%", height: "90%", marginBottom: "10px" }} /> */}

                      <div
                        className="chart_container"
                        style={{
                          width: "100%",
                          height: "87%",
                          borderRadius: "9px",
                          padding: "10px",
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "black",
                        }}
                      >
                        {/* <Bar data={data} options={options} />; */}
                        <Line data={data} />;
                      </div>
                    </div>
                    <br />

                    <div
                      class="col-md-6 col-sm-6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "right",
                      }}
                    >
                      <Chart
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "right",
                        }}
                        chartType="PieChart"
                        data={PIdata}
                        options={PIoptions}
                        width={"100%"}
                        height={"400px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  );
};

// javascript:void(0)

export default Dashboard;
