import { hover } from "@testing-library/user-event/dist/hover";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { MdReceipt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { incNumber } from "../../Redux/actions";

const Table_form11 = ({ FilteredData }) => {
  const dispatch = useDispatch();
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };

  const navigate = useNavigate();

  // for rejected

  const learningform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/drivingLicence/learningDl/${id}`);
  };

  const parmanantform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/drivingLicence/permanantDl/${id}`);
  };

  const renewform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/drivingLicence/RenewDL/${id}`);
  };

  const panCard = (id) => {
    navigate(`${process.env.PUBLIC_URL}/drivingLicence/RenewDL/${id}`);
  }

  const egazetteform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/egazette/create/${id}`);
  };
  const foodLicenceform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/FoodLicence/create/${id}`);
  };
  const compGSTform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/Company_Gst/create/${id}`);
  };
  const indiGSTform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/Individual_Gst/create/${id}`);
  };

  const passportform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/PassportForm/create/${id}`);
  };

  const shopActform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/ShopActForm/create/${id}`);
  };
  const udhamform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/UdhamAbhar/create/${id}`);
  };

  const voterform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/VoterCard/create/${id}`);
  };

  const ewsform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/ews/${id}`);
  };

  const age_nationalityform = (id) => {
    navigate(`${process.env.PUBLIC_URL}/age_nationality/${id}`);
  };

  const incomeForm = (id) => {
    navigate(`${process.env.PUBLIC_URL}/income/${id}`);
  };

  const non_crymelayeForm = (id) => {
    navigate(`${process.env.PUBLIC_URL}/non_cremyLayer/${id}`);
  };

  // for view form details

  const e_gazetteview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_egazzet/${id}`);
  };

  const learningview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_learning/${id}`);
  };

  const renewview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_renew/${id}`);
  };

  const permanantview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_permanant/${id}`);
  };

  const compGSTview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_compGST/${id}`);
  };

  const indiGSTview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_indiGST/${id}`);
  };

  const passportview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_passport/${id}`);
  };

  const shopActview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_shopAct/${id}`);
  };

  const udhamAdharview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_udhamadhar/${id}`);
  };

  const voterCardview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_voterCard/${id}`);
  };

  const foodLicenceview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_foodLicence/${id}`);
  };

  const ewsview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_ews/${id}`);
  };

  const incomeview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_income/${id}`);
  };

  const Non_Cremylayerview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_Non_Cremylayer/${id}`);
  };

  const ageNationalityview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_ageNationality/${id}`);
  };
  const receiptFun = (id) => {
    navigate(`${process.env.PUBLIC_URL}/receipt/${id}`);
  };


  const columns = [
    {
      name: "New",
      width: "110px",

      selector: (row, i) => (
        <div
          className="d-flex justify-content-space-between"
          style={{
            fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
          }}
        >
          {i + 1} {row?.isNew && row.status != "IN-PROGRESS" ? <div className="new">New</div> : <></>}
        </div>
      ),
    },
    {
      name: "Application-Type",
      width: "200px",
      selector: (row) => {
        if (row.application_type == "learning") {
          return (
            <div
              style={{
                fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
              }}
              className="row"
            >
              <div className="col-lg-8">
                <button
                  type="button "
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",

                    color: "blue",
                    fontSize: "15px",
                    fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                  }}
                  onClick={() => {
                    console.log(211, row.status);
                    if (row.status == "REJECTED") {
                      learningform(row._id);
                    } else {
                      navigate(
                        `${process.env.PUBLIC_URL}/application_learning/${row._id}`
                      );
                    }
                  }}
                >
                  Learning DL
                </button>
              </div>
              <div className="col-lg-2 text-center">
                <MdReceipt
                  style={{ cursor: "pointer", fontSize: "20px" }}
                  onClick={() => receiptFun(row._id)}
                />
              </div>
            </div>
          );
        } else if (row.application_type == "permanant") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        parmanantform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_permanent/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    permanent DL
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "renew") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        renewform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_renewal/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    {" "}
                    Renew DL{" "}
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "e_gazzet") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        egazetteform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_eGazzet/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    e_gazette
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "foodLicence") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        foodLicenceform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_foodLicence/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    {" "}
                    Food Licence
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "CompanyGST") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        compGSTform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_compGST/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    {" "}
                    companyGST
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "indGST") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        indiGSTform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_indiGST/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    individualGST{" "}
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "passport") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        passportform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_passport/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    passport{" "}
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "shopAct") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        shopActform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_shopAct/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    shopAct{" "}
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "udhamAdhar") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        udhamform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_udhamadhar/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    UdhamAdhar{" "}
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else if (row.application_type == "voterCard") {
          return (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <button
                    type="button "
                    onClick={() => {
                      if (row.status == "REJECTED") {
                        voterform(row._id);
                      } else {
                        navigate(
                          `${process.env.PUBLIC_URL}/application_voterCard/${row._id}`
                        );
                      }
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",

                      color: "blue",
                      fontSize: "15px",
                      fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
                    }}
                  >
                    voter Card{" "}
                  </button>
                </div>
                <div className="col-lg-2">
                  <MdReceipt
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => receiptFun(row._id)}
                  />
                </div>
              </div>
            </>
          );
        } else {
          return row.application_type;
        }
      },
    },

    {
      name: "Created By ",
      width: "250px",
      selector: (row) => (
        <div
          style={{
            fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
          }}
        >
          {" "}
          {row.createdByName}
        </div>
      ),
    },
    {
      name: "Applicant",
      width: "250px",
      selector: (row) => {
        if (
          row.application_type == "learning" ||
          row.application_type == "permanant" ||
          row.application_type == "renew"
        ) {
          return (
            <div style={{ fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "" }}>
              {row.application_first_name + " " + row.application_last_name}
            </div>
          );
        } else if (row.application_type == "CompanyGST") {
          return (
            <div style={{ fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "" }}>
              {row.companyName}
            </div>
          );
        } else if (row.application_type == "indGST") {
          return (
            <div style={{ fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "" }}>
              {row.customerName}
            </div>
          );
        } else if (

          row.application_type == "passport" ||
          row.application_type == "e_gazzet" ||
          row.application_type == "foodLicence"
        ) {
          return (
            <div style={{ fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "" }}>
              {row.firstName + " " + row.lastName}
            </div>
          );
        } else if (row.application_type == "shopAct") {
          return (
            <div style={{ fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "" }}>
              {row.FullNameEnglish}
            </div>
          )
        } else if (row.application_type == "udhamAdhar") {
          return (
            <div style={{ fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "" }}>
              {row.Owner_Name}
            </div>
          );
        } else if (row.application_type == "voterCard") {
          return (
            <div style={{ fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "" }}>
              {row.name + " " + row.Surname}
            </div>
          );
        }
      },
    },
    {
      name: "Status ",
      width: "150px",
      selector: (row) => (
        <div
          style={{
            fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
          }}
        >
          {" "}
          {row.status}
        </div>
      ),
    },

    {
      name: "Created On ",
      width: "250px",
      selector: (row) => (
        <div
          style={{
            fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
          }}
        >
          {" "}
          {GetDate(row.createdAt)}
        </div>
      ),
    },
    {
      name: "Updated Date ",
      width: "250px",
      selector: (row) => (
        <div
          style={{
            fontWeight: row?.isNew && row.status != "IN-PROGRESS" ? "bold" : "",
          }}
        >
          {" "}
          {GetDate(row.updatedAt)}
        </div>
      ),
    },
  ];
  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWeight: "600",
        fontSize: "18px",
        //  width:"fit-content"
        // Add any other CSS properties you want to apply to the rows
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        border: "1px solid black",
        backgroundColor: "#0094ef  ",
        color: "white",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        border: "1px solid black",
        width: "fit-content"
      },
    },
    cells: {
      style: {
        // Add any styles for the regular cells
        border: "1px solid black",
        width: "fit-content"
      },
    },
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={FilteredData}
        pagination={true}
        customStyles={customStyles}
      />
    </div>
  );
};

export default Table_form11;
